import { useEffect, useState } from 'react';
import FilterListIcon from '@material-ui/icons/FilterList';
import { Button, DialogActions, DialogContent, DialogTitle, IconButton } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import CloseIcon from '@material-ui/icons/Close';
import VehicleListFilters from './VehicleListFilters';
import useGetFiltersInfo from '../hooks/useGetFiltersInfo';
import { TopDialog } from './SendMessageModal';

const VehicleFiltersModal = () => {
  const { t } = useTranslation('search');
  const { data, loading } = useGetFiltersInfo();
  const [visible, setVisible] = useState(false);

  const toggleModal = () => setVisible(!visible);

  useEffect(() => {
    setTimeout(() => {
      document.querySelector('#top')?.scrollIntoView({ block: 'end', behavior: 'smooth' });
    }, 0);
  }, [visible]);

  return (
    <>
      <Button startIcon={<FilterListIcon />} onClick={toggleModal}>
        {t('Filters')}
      </Button>
      <TopDialog open={visible}>
        <DialogTitle>
          {t('Filters')}
          <IconButton onClick={toggleModal} className="float-right">
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <VehicleListFilters />
        </DialogContent>
        <DialogActions>
          <Button variant="contained" color="primary" onClick={toggleModal} disabled={loading}>
            {loading ? t('general:Loading') : `Ver ${data?.total} vehiculos`}
          </Button>
        </DialogActions>
      </TopDialog>
    </>
  );
};

export default VehicleFiltersModal;
